import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { flow } from "fp-ts/lib/function";
import * as TE from "fp-ts/lib/TaskEither";
import * as yup from "yup";

import { useEfemeralUrlError } from "@hooks/useEfemeralUrlError";
import { signIn, Errors } from "@services/index";

import { Box } from "@components/common/ResponsiveBox";
import { H2 } from "@components/common/Headings";
import { Spacer } from "@components/common/Spacer";
import Link from "@components/common/Link";
import Button from "@components/common/Button";
import { Divider } from "@components/common/Divider";
import { Input, Form, useForm } from "@components/common/Input";
import { BackButton } from "@components/common/BackBtn";

const initialState = { email: "", password: "" };

const schema = yup.object({
  email: yup.string().email().required(),
  password: yup.string().required().min(8),
  source: yup.string(),
  timeZone: yup.string(),
  plan: yup.string(),
});

type FormattedError = {
  formErrors: string[];
};

export const SignInForm = ({
  accentureLoginUrl = "/login/sso",
}: {
  accentureLoginUrl?: string;
}) => {
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const [source, setSource] = useState("");
  const [plan, setPlan] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const [showPassword, toggleShowPassword] = useState(false);
  const [userHasAccount, setUserHasAccount] = useState(false);
  const urlError = useEfemeralUrlError();
  const getAuthMethod = (formattedError: FormattedError) => {
    setLoading(false);
    const { formErrors } = formattedError;
    const [error] = formErrors;
    if (error === Errors.INVALID_AUTH_METHOD) {
      setUserHasAccount(true);
    }
    return formattedError;
  };

  useEffect(
    () => setTimeZone(Intl.DateTimeFormat().resolvedOptions().timeZone),
    [],
  );
  const { useField, handleSubmit, formErrors, status, result } = useForm(
    initialState,
    schema,
    flow((credentials) => {
      setLoading(true);
      return signIn({ ...credentials, timeZone, source, plan });
    }, TE.mapLeft(getAuthMethod)),
  );

  const email = useField("email");
  const password = useField("password");

  const getQueryValue = (query: string | string[] = ""): string =>
    Array.isArray(query) ? query[0] : query;

  useEffect(() => {
    const sourceParam = getQueryValue(router.query.source);
    if (sourceParam) {
      setSource(sourceParam);
    }

    const planParam = getQueryValue(router.query.plan);
    if (planParam) {
      setPlan(planParam);
    }
  }, []);

  useEffect(() => localStorage.removeItem("email"), []);

  useEffect(() => {
    if (formErrors[0] === Errors.USER_NOT_VERIFIED) {
      localStorage.setItem("email", email.value);
      router.push(`/account/verify`);
    }
  }, [formErrors]);

  useEffect(() => {
    if (result === "/account/two-factor") {
      localStorage.setItem("email", email.value);
    }

    if ((status === "DONE" || status === "VALID") && result) {
      router.push(result);
    }
  }, [status, result]);

  return (
    <section>
      {userHasAccount ? (
        <>
          <BackButton onPress={() => setUserHasAccount(false)}>Back</BackButton>
          <Spacer size="xxg" />
          <H2>You've previously signed in with a third party account.</H2>
          <Spacer size="xg" />
          {email.value}
          <Spacer size="xhg" />
        </>
      ) : (
        <Form
          onSubmit={handleSubmit}
          errors={urlError ? formErrors.concat(urlError) : formErrors}
        >
          <Input {...email} type="email" label="Email" />
          <Input
            {...password}
            disableAutoComplete
            type={showPassword ? "text" : "password"}
            icon={showPassword ? "eye" : "eye-close"}
            iconAction={() => toggleShowPassword(!showPassword)}
            iconRight
            label="Password"
          >
            <Link small dark href="/account/forgot-password">
              Forgot your password?
            </Link>
          </Input>
          <Form.Actions>
            <Button
              color="blue"
              disabled={status !== "VALID"}
              type="submit"
              block
              loading={loading}
              loadingVariant="dark"
            >
              Log In
            </Button>
          </Form.Actions>
        </Form>
      )}

      <Box mB="xg">
        {!userHasAccount && <Divider />}

        <a style={{ textDecoration: "none" }} href={accentureLoginUrl}>
          <Button
            block
            outline
            color="white"
            icon={{
              name: "accenture",
            }}
          >
            Log in with Accenture account
          </Button>
        </a>
      </Box>
    </section>
  );
};
